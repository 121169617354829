class FilterRange {
    constructor(
        attributeId,
        attributeTitle,
        valueMin,
        valueMax,
        selectedValueMin,
        selectedValueMax
    ) {
        this.attributeId = attributeId
        this.attributeTitle = attributeTitle
        this.valueMin = valueMin
        this.valueMax = valueMax
        this.selectedValueMin = selectedValueMin
        this.selectedValueMax = selectedValueMax
    }
}

export default class SearchFilters {
    constructor() {
        this.buttonShow = document.querySelector('.js-filters-button-show')
        this.buttonHide = document.querySelector('.js-filters-button-hide')
        this.form = document.querySelector('#js-filters-form')
        this.section = document.querySelector('.js-filters-section')
        this.inputsRanges = document.getElementsByClassName('js-filters-form-control-range')
        SearchFilters.filtersRanges = []
        this.isFilterRangesCreated = false
        this.init()
    }

    init() {
        if (this.buttonShow != null) {
            this.buttonShow.addEventListener("click", () => {
                this.buttonShow.classList.add("d-none")
                this.buttonHide.classList.remove("d-none")
                this.section.classList.remove("d-none")
                
                if (this.isFilterRangesCreated == false) {
                    this.isFilterRangesCreated = true
                    SearchFilters.filtersRanges.forEach((filterRange) => {
                        this.createFilterRange(filterRange)
                    })
                }
            })
        }
        if (this.buttonHide != null) {
            this.buttonHide.addEventListener("click", () => {
                this.buttonHide.classList.add("d-none")
                this.buttonShow.classList.remove("d-none")
                this.section.classList.add("d-none")
            })
        }
        if (this.form != null) {
            var linkElement = document.createElement("link");
            linkElement.rel = "stylesheet";
            linkElement.href = "/static/jquery-ui-1.14.1.min.css";
            document.head.appendChild(linkElement);
        }
    }
    
    showButtonShow() {
        this.buttonShow.classList.remove("d-none")
    }
    
    addFilterRange(
        attributeId,
        attributeTitle,
        valueMin,
        valueMax,
        selectedValueMin,
        selectedValueMax
    ) {
        SearchFilters.filtersRanges.push(
            new FilterRange(
                attributeId,
                attributeTitle,
                valueMin,
                valueMax,
                selectedValueMin,
                selectedValueMax
            )
        )
    }
    
    createFilterRange(filterRange) {
        let selectedValueMin = filterRange.valueMin
        let selectedValueMax = filterRange.valueMax
        if (filterRange.selectedValueMin != null) {
            selectedValueMin = filterRange.selectedValueMin
        }
        if (filterRange.selectedValueMax != null) {
            selectedValueMax = filterRange.selectedValueMax
        }
        $("#filter_attribute_range_display_value_left_" + filterRange.attributeId).html(selectedValueMin);
        $("#filter_attribute_range_display_value_right_" + filterRange.attributeId).html(selectedValueMax);
        
        SearchFilters.setInputRangeMinValue(filterRange.attributeId, selectedValueMin)
        SearchFilters.setInputRangeMaxValue(filterRange.attributeId, selectedValueMax)
        
        $("#filter_attribute_range_" + filterRange.attributeId).slider({
            range: true,
            min: filterRange.valueMin,
            max: filterRange.valueMax,
            values: [ selectedValueMin, selectedValueMax ],
            slide: function( event, ui ) {
                SearchFilters.setInputRangeMinValue(filterRange.attributeId, ui.values[0])
                SearchFilters.setInputRangeMaxValue(filterRange.attributeId, ui.values[1])
                
                $("#filter_attribute_range_display_value_left_" + filterRange.attributeId).html(ui.values[0]);
                $("#filter_attribute_range_display_value_right_" + filterRange.attributeId).html(ui.values[1]);
            }
        });
    }
    
    static setInputRangeMinValue(attributeId, selectedValue) {
        let inputMin = $("#filter_attribute_min_" + attributeId)
        if (inputMin != null) {
            inputMin[0].value = selectedValue
        }
    }
    
    static setInputRangeMaxValue(attributeId, selectedValue) {
        let inputMax = $("#filter_attribute_max_" + attributeId)
        if (inputMax != null) {
            inputMax[0].value = selectedValue
        }
    }
}
