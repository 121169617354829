import './polyfills/CustomEvent'
import { LuminousGallery } from 'luminous-lightbox'
import Slider from './App/Sliders'
import Prices from './App/Prices'
import Stocks from './App/Stocks'
import Header from './App/Header'
import AddToCart from './App/AddToCart'
import AvailabilityAlert from './App/AvailabilityAlert'
import Session from './App/Session'
import AdminLinks from './App/AdminLinks'
import GoogleTagManager from './App/GoogleTagManager/GoogleTagManager'
import CookiesConsentAag from './App/CookiesConsentAag'
import CitySelector from './App/CitySelector'
import ToggleElement from './App/ToggleElement'
import NewsletterForm from './App/NewsletterForm'
import LoadAddress from './App/LoadAddress'
import MainMenu from './App/MainMenu'
import OrderForm from './App/OrderForm'
import Blog from './App/Blog'
import Expand from './App/Expand'
import SearchFilters from './App/SearchFilters'
import SearchForm from './App/SearchForm'
import GoTo from './App/GoTo'
import RegisterForm from './App/RegisterForm'
import Dropdown from './App/Dropdown'
import ContactForm from './App/ContactForm'
import Breakpoints from './App/Breakpoints'
import { WindowOrientation } from './App/WindowOrientation'
import Modals from './App/Modals'
import UnavailableProductModal from './App/UnavailableProductModal'
import ProductDetails from './App/ProductDetails'
import ProductPreviews from './App/ProductPreviews'
import ProductReviews from './App/ProductReviews'
import AskReplacementModal from './App/AskReplacementModal'
import updateNotification from './App/MessageNotification'
import Matomos from './App/Matomos'
import LazyLoad from 'vanilla-lazyload'
import * as Sentry from "@sentry/browser"
import { Integrations } from "@sentry/tracing"
import uniqid from 'uniqid'


function resizeHomepageBannerProviders() {
    let slider_container = document.getElementById("js-slider-container-providers-preview")
    if (slider_container != null)
        slider_container.classList.remove("d-none")
    
    let bannersProviders = document.getElementsByClassName("banner-provider")
    if (bannersProviders != null) {
        for (let i=0; i < bannersProviders.length; i++) {
            let bannerProvider = bannersProviders[i]
            let sizePx = Math.round(innerHeight / 10) + "px"
            bannerProvider.style.height = sizePx
        }
    }
    if (isResizeHomepageBannerProvidersOk() == false)
        setTimeout(resizeHomepageBannerProviders, 250)
    else
        setTimeout(isNeedResizeHomepageBannerProviders, 1000)
}

function isResizeHomepageBannerProvidersOk() {
    let isResizeOk = true
    let bannersProviders = document.getElementsByClassName("banner-provider")
    if (bannersProviders != null) {
        for (let i=0; i < bannersProviders.length; i++) {
            let bannerProvider = bannersProviders[i]
            let sizePx = Math.round(innerHeight / 10) + "px"
            bannerProvider.style.height = sizePx
            if ((bannerProvider.offsetHeight + "px") != sizePx) {
                isResizeOk = false
                break
            }
        }
    } else
        isResizeOk = false
    return isResizeOk
}

function isNeedResizeHomepageBannerProviders() {
    if (isResizeHomepageBannerProvidersOk() == false) {
        resizeHomepageBannerProviders()
    }
}

if (window.isProduction) {
    Sentry.init({
        dsn: `https://cf090de51e8f4401ab6a332f4d450a59@o468496.ingest.sentry.io/5496419`,
        integrations: [new Integrations.BrowserTracing()],
    })
}

window.modalInstances = []

Session.request()
window.lazyLoad = new LazyLoad({
    elements_selector: `img.lazy`
})

window.onbeforeprint = () => {
    window.lazyLoad.loadAll()
}

window.matomos = new Matomos()

function createMatomos() {
    const matomos_datas = [...document.querySelectorAll(`.matomo_data`)]

    matomos_datas.forEach(tag => {
        window.matomos.createMatomo(tag)
    })
}

document.addEventListener("addtocart.create.all.end", function() {
    createMatomos()
})

const addToCartForms = [...document.querySelectorAll(`form.add_to_cart`)]

addToCartForms.forEach(form => {
    new AddToCart(form)
})
document.dispatchEvent(new Event('addtocart.create.all.end'))

const availabilityAlertForms = [...document.querySelectorAll(`form.availability-alert`)]

availabilityAlertForms.forEach(form => {
    new AvailabilityAlert(form)
})

new Header()
new Prices()
new Stocks()
new AdminLinks()
new GoogleTagManager()
window.cookiesConsentAag = new CookiesConsentAag()
new NewsletterForm()
new OrderForm()
new LoadAddress()
new SearchForm()
new Breakpoints()
new Blog()
new ContactForm()
new UnavailableProductModal()
new AskReplacementModal()

const mainMenu = document.querySelector(`.js-MainMenu`)
if (mainMenu) {
    new MainMenu(mainMenu)
}

const zipInputs = [...document.querySelectorAll(`.js-InputZip`)]

zipInputs.forEach(input => {
    new CitySelector(input)
})

const toggleElements = [...document.querySelectorAll(`.js-ToggleElement`)]

toggleElements.forEach(trigger => {
    new ToggleElement(trigger)
})

const goToSelects = [...document.querySelectorAll(`.js-GoTo`)]

goToSelects.forEach(select => {
    new GoTo(select)
})

const expands = [...document.querySelectorAll(`.js-Expand`)]

expands.forEach(trigger => {
    new Expand(trigger)
})

window.searchFilters = new SearchFilters()

const registerForms = [...document.querySelectorAll(`.js-RegisterForm`)]

registerForms.forEach(form => {
    new RegisterForm(form)
})

new ProductDetails()

const productPreviews = [...document.querySelectorAll(`.js-ProductPreviews`)]

productPreviews.forEach(element => {
    new ProductPreviews(element)
})

resizeHomepageBannerProviders()
window.addEventListener("resize", function(event) {
    resizeHomepageBannerProviders()
});

const productReviews = [...document.querySelectorAll(`.js-Product__Reviews-Trigger`)]

productReviews.forEach(trigger => {
    new ProductReviews(trigger)
})

const galleries = document.querySelectorAll(`[data-fancybox="gallery"]`)
new LuminousGallery(galleries)

const dropdowns = [...document.querySelectorAll(`.js-Dropdown`)]

dropdowns.forEach(dropdown => {
    new Dropdown(dropdown, dropdowns)
})

updateNotification()

new WindowOrientation
new Modals
